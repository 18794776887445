// skills.js

import axios from "../../plugins/apiServices";

export const state = {
  skills: [],
};

export const getters = {
  getSkills(state) {
    return state.skills;
  },
};

export const mutations = {
  SET_SKILLS(state, value) {
    state.skills = value;
  },
};

export const actions = {
  async addSkill({ commit }, data) {
    console.log("data is", data)
    try {
      let resp = await axios.post("/api/create-skills", data);
      if (resp.data.status === true) {
        commit("SET_SKILLS", [...state.skills, resp.data.data.skill]);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async updateSkill({ commit, state }, payload) {
    try {
      let resp = await axios.post("/api/update-skill", payload);
      if (resp.data.status === true) {
        let skill = resp.data.data.skill;
        const updatedSkills = state.skills.map((s) =>
          s.id === skill.id ? skill : s
        );
        commit("SET_SKILLS", updatedSkills);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async setSkills({ commit }) {
    try {
      let resp = await axios.get("/api/skill-list");

      console.log("resp", resp.data);
      if (resp.data.message == 'Skills fetched successfully') {
        commit("SET_SKILLS", resp.data.skills);
        
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async deleteSkill({ commit }, skillId) {
    try {
      let resp = await axios.get("/api/delete-skill/" + skillId);
      if (resp.data.status === true) {
        const updatedSkills = state.skills.filter((skill) =>
          skill.skills_id !== skillId
        );
        commit("SET_SKILLS", updatedSkills);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async getSkillById(_unused, skillId) {
    try {
      let resp = await axios.get("/api/edit-skill?id=" + skillId);
      console.log("resp", resp.data);
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }
};
