<template>
 <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
<style>
.v-toast__item{
  min-width: 220px !important;
}
.loader-setup{
  width: 23px !important;
  height: 23px !important;
  margin-left: 9px;
}
</style>