// service_categories.js

import axios from "../../plugins/apiServices";

export const state = {
  scategory: [],
};

export const getters = {
    setScategory(state) {
    return state.scategory;
  },
};

export const mutations = {
  SET_SCATEGORY(state, value) {
    state.scategory = value;
  },
};

export const actions = {
  async addScategory({ commit }, data) {
    console.log("data is", data)
    try {
      let resp = await axios.post("/api/create-servicecategory", data);
      if (resp.data.status === true) {
        commit("SET_SCATEGORY", [...state.scategory, resp.data.data.service_categories]);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async updateScategory({ commit, state }, payload) {
    try {
      let resp = await axios.post("/api/update-scategory", payload);
      if (resp.data.status === true) {
        let scategory = resp.data.data.scategory;
        const updatedscategory = state.scategory.map((s) =>
          s.id === scategory.id ? scategory : s
        );
        commit("SET_SCATEGORY", updatedscategory);
      }
      return resp;
    } catch (error) {
    
      console.error(error);
    }
  },

  async setScategory({ commit }) {
    try {
      let resp = await axios.get("/api/servicecategory-list");

     
      if (resp.data.message == 'Service Categories fetched successfully') {
        commit("SET_SCATEGORY", resp.data.service_categories);
        console.log("resp is", resp.data.service_categories);
      }
      return resp.data.service_categories;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async deleteScategory({ commit }, service_categories_id) {
    try {
      let resp = await axios.get("/api/delete-servicecategory/" + service_categories_id);
      if (resp.data.status === true) {
        const updatedScategory = state.scategory.filter((scategory) =>
          scategory.service_categories_id !== service_categories_id
        );
        commit("SET_SCATEGORY", updatedScategory);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async getScategoryById(_unused, service_categories_id) {
    try {
      let resp = await axios.get("/api/edit-servicecategory?id=" + service_categories_id);
      console.log("resp", resp.data);
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }
};
