import axios from '@/plugins/apiServices.js' 
export const state = {
    availability : [],
};

export const getters = {
    getAvailability(state) {
        return state.availability
    }
};

export const mutations = {
    SET_AVAILABILITY(state , value) {
        state.availability = [...value]
    }
};

export const actions = {
      // eslint-disable-next-line no-empty-pattern
    async setUserAvailability({},payload) {
        // return payload
        let resp = await axios.post("/api/user/update-partner-availability" , payload);
        return resp;
    },
    async getUserAvailability({commit},payload) {
        // return payload
        let resp = await axios.get(`/api/user/get-partner-availability/${payload}`);

        if (resp && resp.data && resp.data.data) {
            commit('SET_AVAILABILITY' , resp.data.data.availabilities)
        }

        return resp
    },
};