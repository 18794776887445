// industry.js

import axios from "../../plugins/apiServices";

export const state = {
  industry: [],
};

export const getters = {
  getIndustry(state) {
    return state.industry;
  },
};

export const mutations = {
  SET_INDUSTRY(state, value) {
    state.industry = value;
  },
};

export const actions = {
  async addIndustry({ commit }, data) {
    console.log("data is", data)
    try {
      let resp = await axios.post("/api/create-industries", data);
      if (resp.data.status === true) {
        commit("SET_INDUSTRY", [...state.industry, resp.data.data.industry]);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async updateIndustry({ commit, state }, payload) {
    try {
      let resp = await axios.post("/api/update-industry", payload);
      if (resp.data.status === true) {
        let industry = resp.data.data.industry;
        const updatedIndustry = state.industry.map((s) =>
          s.id === industry.id ? industry : s
        );
        commit("SET_INDUSTRY", updatedIndustry);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async setIndustry({ commit }) {
    try {
      let resp = await axios.get("/api/industries-list");

      console.log("resp", resp.data);
      if (resp.data.message == 'Industries fetched successfully') {
        commit("SET_INDUSTRY", resp.data.industries);
        
      }
      return resp.data.industries;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async deleteIndustry({ commit }, industryId) {
    try {
      let resp = await axios.get("/api/delete-industries/" + industryId);
      if (resp.data.status === true) {
        const updatedIndustry = state.industry.filter((industry) =>
          industry.industry_id !== industryId
        );
        commit("SET_INDUSTRY", updatedIndustry);
      }
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  },

  async getIndustryById(_unused, industryId) {
    try {
      let resp = await axios.get("/api/edit-industries?id=" + industryId);
      console.log("resp", resp.data);
      return resp;
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }
};
